// Import tailwind css, webpack will automatically generate <link> for us
import "../css/style.css";
import {
    createChart,
    ISeriesApi,
    SeriesDataItemTypeMap,
    TickMarkType,
    UTCTimestamp,
} from "lightweight-charts";

async function getDataFromIndodax(): Promise<SeriesDataItemTypeMap> {
    // Get data from Indodax
    // Generate start time and end time
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 60);

    const fromTime = Math.floor(fromDate.getTime() / 1000);
    const currentTime = Math.floor(Date.now() / 1000);
    console.log("getDataFromIndodax", currentTime);

    const url = `https://indodax-worker.bayualsyah.workers.dev/history?symbol=UNIIDR&resolution=60&from=${fromTime}&to=${currentTime}`;
    const response = await fetch(url);
    console.log("getDataFromIndodax", url);
    console.log("getDataFromIndodax", response);

    // Convert Indodax data to OHLC item
    // https://github.com/tradingview/lightweight-charts/blob/master/docs/ohlc.md
    const indodaxData = await response.json();
    const barData = indodaxData.t.map((timeStamp: number, index: number) => {
        return {
            time: timeStamp as UTCTimestamp,
            open: indodaxData.o[index],
            high: indodaxData.h[index],
            low: indodaxData.l[index],
            close: indodaxData.c[index],
        };
    });
    return barData;
}

function setupChartSeries(
    data: SeriesDataItemTypeMap
): ISeriesApi<"Candlestick"> {
    const chart = createChart(document.body, {
        width: window.innerWidth,
        height: window.innerHeight,
        localization: {
            locale: "id-ID",
            timeFormatter: (time: UTCTimestamp): string => {
                const localTime = new Date(time * 1000);
                if (localTime.getMinutes() <= 9) {
                    return `${localTime.getDate()}/${localTime.getMonth()}/${localTime.getFullYear()} ${localTime.getHours()}:0${localTime.getMinutes()}`;
                }
                return `${localTime.getDate()}/${localTime.getMonth()}/${localTime.getFullYear()} ${localTime.getHours()}:${localTime.getMinutes()}`;
            },
        },
        timeScale: {
            barSpacing: 3,
            fixLeftEdge: true,
            lockVisibleTimeRangeOnResize: true,
            rightBarStaysOnScroll: true,
            borderVisible: false,
            visible: true,
            timeVisible: true,
            secondsVisible: true,
            tickMarkFormatter: (
                time: UTCTimestamp,
                tickMarkType: TickMarkType
            ): string => {
                const localTime = new Date(time * 1000);
                if (localTime.getMinutes() <= 9) {
                    return `${localTime.getHours()}:0${localTime.getMinutes()}`;
                }
                return `${localTime.getHours()}:${localTime.getMinutes()}`;
            },
        },
    });

    const candleStickSeries = chart.addCandlestickSeries();
    // @ts-ignore
    candleStickSeries.setData(data);

    return candleStickSeries;
}

async function liveUpdateChartSeries(series: ISeriesApi<"Candlestick">) {
    // Get data from Indodax
    // Generate start time and end time
    const fromDate = new Date();
    fromDate.setMinutes(fromDate.getMinutes() - 61);

    const fromTime = Math.floor(fromDate.getTime() / 1000);
    const currentTime = Math.floor(Date.now() / 1000);
    console.log(
        `liveUpdateChartSeries fromTime=${fromTime} currentTime=${currentTime}`
    );

    const url = `https://indodax-worker.bayualsyah.workers.dev/history?symbol=UNIIDR&resolution=60&from=${fromTime}&to=${currentTime}`;
    const response = await fetch(url);
    console.log("liveUpdateChartSeries", url);
    console.log("liveUpdateChartSeries", response);

    // Convert Indodax data to OHLC item
    // https://github.com/tradingview/lightweight-charts/blob/master/docs/ohlc.md
    const indodaxData = await response.json();
    const barData = indodaxData.t.map((timeStamp: number, index: number) => {
        return {
            time: timeStamp as UTCTimestamp,
            open: indodaxData.o[index],
            high: indodaxData.h[index],
            low: indodaxData.l[index],
            close: indodaxData.c[index],
        };
    });

    barData.map((item: SeriesDataItemTypeMap) => {
        // @ts-ignore
        series.update(item);
    });

    // Reconnect in 5 second
    await new Promise((resolve) => setTimeout(resolve, 5000));
    await liveUpdateChartSeries(series);
}

async function main() {
    const indodaxData = await getDataFromIndodax();

    const series = setupChartSeries(indodaxData);

    // Live update data
    liveUpdateChartSeries(series);
}

main();
